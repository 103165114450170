<template>
  <div class="select-card">
    <div class="select-card-title">
      <span class="title-tips">排行统计周期</span>
      <span
        :class="[
          dept.rankingFlag === 1
            ? 'title-item title-item-active'
            : 'title-item',
        ]"
        @click="onSwitch(1)"
        >按日排行</span
      >
      <span
        :class="[
          dept.rankingFlag === 2
            ? 'title-item title-item-active'
            : 'title-item',
        ]"
        @click="onSwitch(2)"
        >按月排行</span
      >
    </div>
    <div class="select-card-bottom">
      <div
        class="select-card-bottom-left top"
        v-loading="largeArea"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
        v-show="isShowLargeArea"
      >
        <div class="select-card-top" style="border: none">
          <div class="left flexBox">
            所辖大区排名
            <el-popover placement="right" trigger="hover" width="500">
              <div>
                规则说明：<br />
                所辖大区为当前登录账号管辖范围内的大区、地区业绩排名。
              </div>
              <i
                slot="reference"
                class="el-icon-question count_box_title_icon"
              ></i>
            </el-popover>
          </div>
          <div
            class="right flexBox"
            v-if="region.sort == 'desc'"
            @click="region_sort('asc')"
          >
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-drop" height="26" width="26" />
            </svg>
            <span>从高到低</span>
          </div>
          <div class="right" v-else @click="region_sort('desc')">
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-rise" height="26" width="26" />
            </svg>
            <span>从低到高</span>
          </div>
        </div>
        <div class="item" v-for="(item, index) in testData" :key="index">
          <div class="item-index">{{ item.sortNum }}</div>
          <div class="item-title">{{ item.deptName }}</div>
          <div class="item-li">
            <el-progress
              class="count_pro"
              :percentage="setPercentage(item.saleRatio)"
              :stroke-width="18"
              :text-inside="true"
              :color="customColor"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="item-last">
            <p class="one" v-if="index == 0">
              {{ item.saleAmount }}<span>万</span>
            </p>
            <p class="two" v-if="index == 1">
              {{ item.saleAmount }}<span>万</span>
            </p>
            <p class="three" v-if="index == 2">
              {{ item.saleAmount }}<span>万</span>
            </p>
            <p class="four" v-if="index > 2">
              {{ item.saleAmount }}<span>万</span>
            </p>
            <p class="small">{{ item.managerName }}</p>
          </div>
        </div>
        <div class="select-card-top" style="border: none">
          <div class="right" style="width: 100%; float: right">
            <el-button
              type="primary"
              @click="more(1)"
              plain
              size="small"
              style="border: none; background: rgba(0, 181, 75, 0.1)"
              >查看更多</el-button
            >
          </div>
        </div>
      </div>
      <div class="block" v-show="isShowLargeArea"></div>
      <div
        class="select-card-bottom-right top"
        v-loading="area"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
      >
        <div class="select-card-top" style="border: none">
          <div class="flexBox">
            所辖地区排名
            <el-popover placement="right" trigger="hover" width="500">
              <div>
                规则说明：<br />
                所辖地区为当前登录账号管辖范围内的地区业绩排名。
              </div>
              <i
                slot="reference"
                class="el-icon-question count_box_title_icon"
              ></i>
            </el-popover>
          </div>
          <div
            class="right flexBox"
            v-if="dept.sort == 'desc'"
            @click="dept_sort('asc')"
          >
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-drop" height="26" width="26" />
            </svg>
            <span>从高到低</span>
          </div>
          <div class="right" v-else @click="dept_sort('desc')">
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-rise" height="26" width="26" />
            </svg>
            <span>从低到高</span>
          </div>
        </div>
        <div class="item" v-for="(item, index) in testData2" :key="index">
          <div class="item-index">{{ item.sortNum }}</div>
          <div class="item-title">{{ item.deptName }}</div>
          <div class="item-li">
            <el-progress
              :percentage="setPercentage(item.saleRatio)"
              :stroke-width="18"
              :text-inside="true"
              color="#23C782"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="item-last">
            <p class="one" v-if="index == 0">
              {{ item.saleAmount }}<span>万</span>
            </p>
            <p class="two" v-if="index == 1">
              {{ item.saleAmount }}<span>万</span>
            </p>
            <p class="three" v-if="index == 2">
              {{ item.saleAmount }}<span>万</span>
            </p>
            <p class="four" v-if="index > 2">
              {{ item.saleAmount }}<span>万</span>
            </p>
            <p class="small">{{ item.managerName }}</p>
          </div>
        </div>
        <div class="select-card-top" style="border: none">
          <div class="right" style="width: 100%; float: right">
            <el-button
              type="primary"
              @click="more(2)"
              plain
              size="small"
              style="border: none; background: rgba(0, 181, 75, 0.1)"
              >查看更多</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="select-card-bottom">
      <div
        class="select-card-bottom-left bottom"
        v-loading="allLargeArea"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
        v-show="isShowLargeArea"
      >
        <div class="select-card-top" style="border: none">
          <div class="left flexBox">
            全国大区排名
            <el-popover placement="right" trigger="hover" width="500">
              <div>
                规则说明：<br />
                营销部下所有大区业绩排名，仅展示排名不展示销售业绩
              </div>
              <i
                slot="reference"
                class="el-icon-question count_box_title_icon"
              ></i>
            </el-popover>
          </div>
          <div
            class="right flexBox"
            v-if="allRegionSrotText == 'desc'"
            @click="allRegionSort('asc')"
          >
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-drop" height="26" width="26" />
            </svg>
            <span>从高到低</span>
          </div>
          <div class="right" v-else @click="allRegionSort('desc')">
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-rise" height="26" width="26" />
            </svg>
            <span>从低到高</span>
          </div>
        </div>
        <div
          class="item-bottom"
          v-for="(item, index) in testData3"
          :key="index"
        >
          <div style="display: flex; align-items: center; padding-bottom: 8px">
            <div class="item-index">{{ item.sortNum }}</div>
            <div class="item-title">
              {{ item.deptName
              }}<span class="item-title-span">{{ item.managerName }}</span>
            </div>
          </div>
          <div class="item-li">
            <el-progress
              class="count_pro"
              :percentage="setPercentage(item.saleRatio)"
              :stroke-width="18"
              :text-inside="true"
              :color="'#8C7CAD'"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
        <div class="select-card-top" style="border: none">
          <div class="right" style="width: 100%; float: right">
            <el-button
              type="primary"
              @click="more(3)"
              plain
              size="small"
              style="border: none; background: rgba(0, 181, 75, 0.1)"
              >查看更多</el-button
            >
          </div>
        </div>
      </div>
      <div class="block" v-show="isShowLargeArea"></div>
      <div
        class="select-card-bottom-right bottom"
        v-loading="allArea"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
      >
        <div class="select-card-top" style="border: none">
          <div class="flexBox">
            全国地区排名
            <el-popover placement="right" trigger="hover" width="500">
              <div>
                规则说明：<br />
                营销部下所有地区业绩排名，仅展示排名不展示销售业绩。
              </div>
              <i
                slot="reference"
                class="el-icon-question count_box_title_icon"
              ></i>
            </el-popover>
          </div>
          <div
            class="right flexBox"
            v-if="allDeptSortText == 'desc'"
            @click="allDeptSort('asc')"
          >
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-drop" height="26" width="26" />
            </svg>
            <span>从高到低</span>
          </div>
          <div class="right" v-else @click="allDeptSort('desc')">
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-rise" height="26" width="26" />
            </svg>
            <span>从低到高</span>
          </div>
        </div>
        <div
          class="item-bottom"
          v-for="(item, index) in testData4"
          :key="index"
        >
          <div style="display: flex; align-items: center; padding-bottom: 8px">
            <div class="item-index">{{ item.sortNum }}</div>
            <div class="item-title">
              {{ item.deptName
              }}<span class="item-title-span">{{ item.managerName }}</span>
            </div>
          </div>
          <div class="item-li">
            <el-progress
              class="count_pro"
              :percentage="setPercentage(item.saleRatio)"
              :stroke-width="18"
              :text-inside="true"
              :color="'#68C1D0'"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
        <div class="select-card-top" style="border: none">
          <div class="right" style="width: 100%; float: right">
            <el-button
              type="primary"
              @click="more(4)"
              plain
              size="small"
              style="border: none; background: rgba(0, 181, 75, 0.1)"
              >查看更多</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      modal-append-to-body
      :show-close="false"
      style="border-radius: 15px"
    >
      <div class="select-card-bottom-left" id="moreArea">
        <div class="select-card-top" style="border: none">
          <div class="left flexBox">
            {{ moreData.title }}
            <span
              style="font-size: 14px; color: #00b54b; cursor: pointer"
              @click="screenShot"
              >一键截图</span
            >
          </div>

          <div
            class="right"
            v-if="moreData.sort == 'desc'"
            @click="sortNum('asc')"
          >
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-drop" height="26" width="26" />
            </svg>
            <span>从高到低</span>
          </div>
          <div class="right flexBox" v-else @click="sortNum('desc')">
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-rise" height="26" width="26" />
            </svg>
            <span>从低到高</span>
          </div>
          <div class="close" @click="dialogVisible = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div>
          <div class="item" v-for="(item, index) in moreDatas" :key="index">
            <div class="item-index">{{ item.sortNum }}</div>
            <div class="item-title">{{ item.deptName }}</div>
            <div class="item-li">
              <el-progress
                :percentage="setPercentage(item.saleRatio)"
                :stroke-width="18"
                :text-inside="true"
                :color="moreData.color"
                :show-text="false"
              ></el-progress>
            </div>
            <div
              class="item-last"
              v-show="moreData.type === 1 || moreData.type === 2"
            >
              <p class="one" v-if="index == 0">{{ item.saleAmount }}万</p>
              <p class="two" v-if="index == 1">{{ item.saleAmount }}万</p>
              <p class="three" v-if="index == 2">{{ item.saleAmount }}万</p>
              <p class="four" v-if="index > 2">{{ item.saleAmount }}万</p>
              <p class="small">{{ item.managerName }}</p>
            </div>
            <div
              class="item-last"
              v-show="moreData.type === 3 || moreData.type === 4"
            >
              <p class="small">{{ item.managerName }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import Moment from "moment";
export default {
  // props: ["date"],
  props: {
    search: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      customColor: "#5E7BBF",
      customColor2: "#23C782",
      dialogVisible: false,
      timeType: 1,
      startTime: "",
      personnelIdentity: "", //身份
      region: {
        pageSize: 6, //每页行数
        pageNum: 1, //页码
        sort: "desc",
      },
      dept: {
        pageSize: 6, //每页行数
        pageNum: 1, //页码
        sort: "desc",
        rankingFlag: 1,
      },
      allRegionSrotText: "desc",
      allDeptSortText: "desc",
      maxprecent: "",
      maxprecent2: "",
      testData: [],
      testData2: [],
      testData3: [],
      testData4: [],
      moreDatas: [],
      dialogTitle: "大区排名",
      moreData: {
        title: "",
        total: 0,
        currentPage: 1,
        sort: "desc",
        color: "#5E7BBF",
        type: 1,
        maxprecent: 0,
      },
      isShowLargeArea: false,
      moreTotal: [],
      largeArea: false,
      area: false,
      allArea: false,
      allLargeArea: false,
      isShowAllLargeArea: false,
    };
  },
  watch: {
    search(newV) {
      this.getDataLeft();
      this.getDataRight();
      this.getDataBottomLeft();
      this.getDataBottomRight();
    },
  },
  mounted() {
    if (sessionStorage.getItem("userInfo")) {
      this.isShowLargeArea =
        JSON.parse(sessionStorage.getItem("userInfo")).marketDataScope === 2
          ? true
          : false;
    }
  },
  methods: {
    screenShot() {
      //获取页面dom
      //这里的html标签是获取页面最大的dom元素；根据实际业务场景自行更改
      const el = document.querySelector("#moreArea");
      html2canvas(el, {
        useCORS: true, //设置可以素材可以跨域，移动端不兼容
        allowTaint: false, //默认就是false，允许跨域
        taintTest: true, //默认就是false，是否在渲染前测试图片
        scale: window.devicePixelRatio, //解决清晰度的问题可以改变scale的值
      }).then((canvas) => {
        //转换base64
        const capture = canvas.toDataURL("image/png");
        //下载浏览器弹出下载信息的属性
        const saveInfo = {
          //导出文件格式自己定义，我这里用的是时间作为文件名
          download:
            this.moreData.title +
            Moment().format("YYYY-MM-DD HH:mm:ss") +
            `.png`,
          href: capture,
        };
        //下载，浏览器弹出下载文件提示
        this.downloadFile(saveInfo);
      });
    },
    downloadFile(saveInfo) {
      const element = document.createElement("a");
      element.style.display = "none";
      for (const key in saveInfo) {
        element.setAttribute(key, saveInfo[key]);
      }
      document.body.appendChild(element);
      element.click();
      setTimeout(() => {
        document.body.removeChild(element);
      }, 300);
    },
    onSwitch(val) {
      this.dept.rankingFlag = val;
      this.getDataLeft();
      this.getDataRight();
      this.getDataBottomLeft();
      this.getDataBottomRight();
    },
    setPercentage(val) {
      if (!val) {
        return 0;
      }
      if (Number(val) < 0) {
        return 0;
      }
      return Number(val) * 100;
    },
    allDeptSort(val) {
      this.allDeptSortText = val;
      this.region.sort = val;
      this.getDataBottomRight();
    },
    allRegionSort(val) {
      this.allRegionSrotText = val;
      this.region.sort = val;
      this.getDataBottomLeft();
    },
    region_sort(val) {
      this.region.sort = val;
      this.getDataLeft();
    },
    dept_sort(val) {
      this.dept.sort = val;
      this.getDataRight();
    },
    sortNum(val) {
      this.moreData.sort = val;
      this.moreSelect(this.moreData.type);
    },
    more(type) {
      this.moreData.currentPage = 1;
      this.moreData.sort = "desc";
      switch (type) {
        case 1:
          this.moreData.title = "所辖大区排名";
          this.moreData.color = "#5E7BBF";
          this.moreData.type = 1;
          break;
        case 2:
          this.moreData.title = "所辖地区排名";
          this.moreData.color = "#23C782";
          this.moreData.type = 2;
          break;
        case 3:
          this.moreData.title = "全国大区排名";
          this.moreData.color = "#8C7CAD";
          this.moreData.type = 3;
          break;
        case 4:
          this.moreData.title = "全国地区排名";
          this.moreData.color = "#68C1D0";
          this.moreData.type = 4;
          break;
        default:
          break;
      }
      this.moreSelect(this.moreData.type);
      this.dialogVisible = true;
    },
    moreSelect(type) {
      // this.$store.dispatch("app/setLoading", true);
      switch (type) {
        case 1:
          this.getDataLeftMore();
          break;
        case 2:
          this.getDataRightMore();
          break;
        case 3:
          this.getDataBottomLeftMore();
          break;
        case 4:
          this.getDataBottomRightMore();
          break;
      }
    },
    // 获取全国地区排名更多数据
    getDataBottomRightMore() {
      const loading = this.$loading({
        lock: true,
        target: "#moreArea",
        text: "Loading",
        fullscreen: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      this.axios
        .post(
          "HOMEAllareaRanking",
          {
            ...this.search,
            sort: this.moreData.sort,
            rankingFlag: this.dept.rankingFlag,
          },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.moreDatas = res.data;
        })
        .finally(() => {
          loading.close();
        });
    },

    // 获取全国大区排名更多数据
    getDataBottomLeftMore() {
      const loading = this.$loading({
        lock: true,
        target: "#moreArea",
        text: "Loading",
        fullscreen: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      this.axios
        .post(
          "HOMEAllLargeRanking",
          {
            ...this.search,
            sort: this.moreData.sort,
            rankingFlag: this.dept.rankingFlag,
          },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.moreDatas = res.data;
        })
        .finally(() => {
          loading.close();
        });
    },
    // 全国地区排名
    getDataBottomRight() {
      this.allArea = true;
      this.axios
        .post(
          "HOMEAllareaRanking",
          {
            ...this.search,
            sort: this.region.sort,
            rankingFlag: this.dept.rankingFlag,
          },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.testData4 = res.data?.splice(0, 6);
        })
        .finally(() => {
          this.allArea = false;
        });
    },
    // 全国大区排名
    getDataBottomLeft() {
      this.allLargeArea = true;
      this.axios
        .post(
          "HOMEAllLargeRanking",
          {
            ...this.search,
            sort: this.region.sort,
            rankingFlag: this.dept.rankingFlag,
          },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.testData3 = res.data?.splice(0, 6);
        })
        .finally(() => {
          this.allLargeArea = false;
        });
    },
    getDataLeft() {
      this.largeArea = true;
      this.axios
        .post(
          "HOMElargeAreaRanking",
          {
            ...this.search,
            sort: this.region.sort,
            rankingFlag: this.dept.rankingFlag,
          },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.testData = res.data?.splice(0, 6);
        })
        .finally(() => {
          this.largeArea = false;
        });
    },
    getDataRight() {
      this.area = true;
      this.axios
        .post(
          "HOMEareaRanking",
          {
            ...this.search,
            sort: this.dept.sort,
            rankingFlag: this.dept.rankingFlag,
          },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.testData2 = res.data?.splice(0, 6);
        })
        .finally(() => {
          this.area = false;
        });
    },
    getDataLeftMore() {
      const loading = this.$loading({
        lock: true,
        target: "#moreArea",
        text: "Loading",
        fullscreen: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      this.axios
        .post(
          "HOMElargeAreaRanking",
          {
            ...this.search,
            sort: this.moreData.sort,
            rankingFlag: this.dept.rankingFlag,
          },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.moreDatas = res.data;
        })
        .finally(() => {
          loading.close();
        });
    },
    getDataRightMore() {
      const loading = this.$loading({
        lock: true,
        target: "#moreArea",
        text: "Loading",
        fullscreen: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      this.axios
        .post(
          "HOMEareaRanking",
          {
            ...this.search,
            sort: this.moreData.sort,
            rankingFlag: this.dept.rankingFlag,
          },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.moreDatas = res.data;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .svgClass {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 50px;

  .select-card-title {
    padding: 13px 0;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 8px;
    .title-tips {
      padding-left: 24px;
      font-size: 14px;
      color: #595959;
      font-weight: 500;
    }

    .title-item {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin: 0 16px;
      padding-bottom: 3px;
      border-bottom: 2px solid #ffffff;
    }

    .title-item-active {
      border-bottom: 2px solid #00b258;
      color: #00b258;
    }
  }
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    .flexBox {
      flex: 1;
    }
    .close {
      // display: inline-block;
      width: 20px !important;
      padding-top: 2px;
      box-sizing: border-box;
      height: 100%;
      margin-left: 30px;
      display: flexbox;
      .el-icon-close {
        margin: auto;
      }
    }
    .right {
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      line-height: 20px;
    }
  }
  .select-card-bottom {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    // align-items: center;
    border-radius: 8px;
    padding: 0 24px;
    padding-bottom: 24px;
    .top {
      background: #f2f7f3;
    }
    .bottom {
      background: #f4f2f7;
    }
    .block {
      width: 24px;
      height: 100%;
      background: #ffffff;
    }
  }
}
.count_box_title_icon {
  font-size: 20px;
  margin-left: 5px;
  vertical-align: middle;
  color: #ffaf02;
}
.select-card-bottom-left,
.select-card-bottom-right {
  background: #ffffff;
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  // min-height: 500px;
  .item-bottom {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 24px;
    box-sizing: border-box;
    align-content: center;
    min-height: 34px;

    .item-index {
      width: 5%;
      display: flex;
      margin-right: 12px;
      font-size: 16px;
      font-family: MiSans-Demibold;
      font-weight: 600;
      color: #262626;
      line-height: 22px;
      align-content: center;
    }
    .item-title {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 22px;
    }

    .item-title-span {
      padding-left: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #595959;
    }
    .item-last {
      width: 10%;
      margin-left: 19px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 1px;
      span {
        font-size: 12px;
        margin-left: 4px;
      }
    }

    .item-li {
      // flex: 1;
      // display: flex;
      width: 100%;
      .count_pro {
        margin: auto;
      }
    }
  }
  .item {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 24px;
    box-sizing: border-box;
    align-content: center;
    min-height: 34px;
    .item-index {
      width: 5%;
      display: flex;
      margin-right: 12px;
      font-size: 16px;
      font-family: MiSans-Demibold;
      font-weight: 600;
      color: #262626;
      line-height: 22px;
      align-content: center;
    }
    .item-title {
      width: 17%;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 22px;
    }
    .item-last {
      width: 10%;
      margin-left: 19px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 1px;
      span {
        font-size: 12px;
        margin-left: 4px;
      }
      .one {
        color: #b57e14;
      }
      .two {
        color: #2b4c67;
      }
      .three {
        color: #5a3e1a;
      }
      .other {
        color: #595959;
      }
      .small {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 12px;
        margin-top: 4px;
      }
    }
  }
  .item-li {
    // flex: 1;
    // display: flex;
    width: 68%;
    .count_pro {
      margin: auto;
    }
  }
}

:deep(.el-progress-bar__inner) {
  border-radius: none;
}

/deep/ .el-dialog__body {
  padding: 0;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  background: #fff;
  border-radius: 15px;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog {
  border-radius: 15px;
}
</style>
