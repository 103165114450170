<template>
  <div class="select-card">
    <div class="select-card-top">
      <div>经营概况</div>
      <div>
        <el-select
          v-model="search.taxFlag"
          placeholder="请选择"
          size="small"
          style="width: 155px"
        >
          <div slot="prefix" class="select-card-top-icon">
            <el-popover placement="left" trigger="hover" width="400">
              <div>
                默认展示无税口径：<br />
                1、非月结订单，除以1.06 <br />
                2、月结订单：试剂入院 /1.13； 正常入院 /1.06<br />
              </div>
              <i
                slot="reference"
                class="el-icon-question count_box_title_icon"
              ></i>
            </el-popover>
          </div>
          <el-option
            v-for="item in taxFlagOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="select-card-bottom">
      <el-cascader
        ref="deptCascaderRef"
        v-model="valueCascader"
        :options="options"
        placeholder="请选择组织架构"
        :props="defaultProps"
        size="small"
        collapse-tags
        clearable
        @change="changeCas"
      />
      <el-select
        v-model="search.productGroupCode"
        placeholder="请选择产品线"
        size="small"
        clearable
        style="margin: 0 20px"
      >
        <el-option
          v-for="item in productLine"
          :key="item.productGroupCode"
          :label="item.productGroupName"
          :value="item.productGroupCode"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="search.hospitalId"
        filterable
        remote
        clearable
        size="small"
        reserve-keyword
        placeholder="请选择医院"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option
          v-for="item in hospitalOption"
          :key="item.hospitalId"
          :label="item.hospitalName"
          :value="item.hospitalId"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="search.dateStr"
        style="margin-left: 20px"
        format="yyyy-MM-dd"
        value-format="yyyyMMdd"
        type="date"
        unlink-panels
        placeholder="选择日期"
        :clearable="false"
        size="small"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 24px;
        padding-bottom: 10px;
      "
    >
      <div style="font-size: 13px; color: #5d5c5c">
        *请选择需要查看的组织架构层级，及所需统计时间
      </div>
      <el-button type="primary" size="small" @click="onSearch">统计</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: {
        taxFlag: 2,
        productGroupCode: "",
        hospitalId: "",
        deptCodeList: [],
        dateStr: "",
      },
      hospitalName: "",
      valueCascader: [],
      defaultProps: {
        checkStrictly: true,
        multiple: true,
        value: "deptCode",
        label: "deptName",
        children: "subList",
        disabled: "cantSelect",
      },
      options: [],
      taxFlagOptions: [
        { value: 1, label: "按含税口径统计" },
        { value: 2, label: "按无税口径统计" },
      ],
      productLine: [],
      hospitalOption: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  watch: {
    hospitalName(newV) {},
  },
  created() {
    let time = new Date(new Date().getTime());
    this.search.dateStr = this.formatDate(time)
      .replace("-", "")
      .replace("-", "");
    this.$emit("on-search", this.search);
    this.axios
      .get("HOMEproductGroupList", "", true, { showLoading: false })
      .then((result) => {
        this.productLine = result.data;
      });
    this.axios
      .get("HOMEauthDeptList", "", true, { showLoading: false })
      .then((result) => {
        this.options = this.formatTreeData(result.data);
      });
  },
  methods: {
    remoteMethod(query) {
      if (query !== " ") {
        this.loading = true;
        this.axios
          .get("HOMEhospitalList", { hospitalName: query }, true, {
            showLoading: false,
          })
          .then((result) => {
            this.hospitalOption = result.data;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.hospitalOption = [];
      }
    },
    formatTreeData(treeList) {
      const result = treeList.map((item) => {
        return {
          deptCode: item.deptCode,
          deptName: item.deptName,
          fullPathCode: item.fullPathCode,
          fullPathName: item.fullPathName,
          level: item.level,
          cantSelect: !item.selectFlag,
          parentCode: item.parentCode,
          selectFlag: item.selectFlag,
          subList: item.subList ? this.formatTreeData(item.subList) : null,
        };
      });
      return result;
    },
    setDisableTreeNodes(level, treeList) {
      const result = treeList.map((item) => {
        return {
          deptCode: item.deptCode,
          deptName: item.deptName,
          fullPathCode: item.fullPathCode,
          fullPathName: item.fullPathName,
          level: item.level,
          cantSelect:
            (item.level === level || level === null) && item.selectFlag === true
              ? false
              : true,
          parentCode: item.parentCode,
          selectFlag: item.selectFlag,
          subList: item.subList
            ? this.setDisableTreeNodes(level, item.subList)
            : null,
        };
      });
      return result;
    },
    changeCas(v) {
      this.$nextTick(() => {
        const checkNodes = this.$refs.deptCascaderRef.getCheckedNodes();
        this.options = checkNodes[0]
          ? this.setDisableTreeNodes(
              checkNodes[0]["data"]["level"],
              this.options
            )
          : this.setDisableTreeNodes(null, this.options);
        this.search.deptCodeList = v.map((item, index) => {
          return item[item.length - 1];
        });
      });
    },
    onSearch() {
      // if (this.search.deptCodeList.length === 0) {
      //   this.$message.warning("请先选择组织架构");
      //   return;
      // }
      if (!this.search.dateStr) {
        this.$message.warning("请先选择日期");
        return;
      }
      this.$emit("on-search", this.search);
    },
    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
  },
};
</script>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  overflow: hidden;
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 24px 24px 10px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ::v-deep {
      .el-input__prefix {
        display: flex;
        align-items: center;
      }
      .el-input__inner {
        border: none;
      }
    }

    .select-card-top-icon {
      color: #262626;
    }
    .count_box_title_icon {
      font-size: 20px;
      margin-right: 5px;
    }
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > div {
      width: 25%;
    }
  }
}
</style>
