<template>
  <div class="box">
    <div class="count_box">
      <div class="count_box_title">
        大区业绩统计<el-popover placement="right" trigger="hover" width="500">
          <div>
            规则说明：<br />
            1、日销售额：每天0点至24点 销售额<br />
            1.1展示数据为选中日期当天销售额<br />
            1.2日环比=（当日数据-前一日数据）/前一日数据*100%<br />
            <br />
            2、周销售额：每周一0点至周日24点销售额<br />
            2.1 如筛选日期为周中，按照选中日期的周一到周日统计。
            示例：选择日期为8月30日，周销售额时间范围是8月28日-9月3日<br />
            2.2 周环比=（当前周销售额—上一周销售额）/上一周数据*100%<br />
            <br />
            3、月销售额：每月1日0点至当月最后一天24点销售额<br />
            3.1 如选中日期为月中，按照当前日期1月1日数据截止整月展示销售额。
            示例：选择日期为8月30日，月销售额时间范围8月1日-8月31日<br />
            3.2 月环比=(当前月销售额—上月销售额）/上月销售额*100%<br />
            <br />
            4、年销售额：每年1日0点至当年最后一天24点销售额<br />
            4.1展示为选中日期所属年份数据<br />
            4.2 同比去年销售数据"
          </div>
          <i slot="reference" class="el-icon-question count_box_title_icon"></i>
        </el-popover>
      </div>
      <div
        class="item"
        :style="{ backgroundColor: cardList[0].backColor }"
        v-loading="card0"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
      >
        <div class="title">
          <p>{{ cardList[0].title }}</p>
          <p style="padding-left: 5px">{{ cardList[0].dateStr }}</p>
        </div>
        <p class="num">
          {{
            cardList[0].num
              ? String(cardList[0].num).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "--"
          }}
          <span style="font-size: 14px; color: #262626">万</span>
        </p>
        <p class="compare" v-show="cardList[0].rise">
          {{ cardList[0].txt }}
          <span
            :class="cardList[0].rise < 0 ? 'compare-con drop' : 'compare-con'"
          >
            <img
              v-if="cardList[0].rise < 0"
              class="question"
              src="@/assets/drop2.png"
            />
            <img v-else class="question" src="@/assets/rise2.png" />
            {{ cardList[0].rise }}%
          </span>
        </p>
        <p class="compare" v-show="!cardList[0].rise">暂无同比数据</p>
        <div class="item_icon">
          <svg-icon class="item_icon_svg" icon-class="tongji0" />
        </div>
      </div>

      <div
        class="item"
        :style="{ backgroundColor: cardList[1].backColor }"
        v-loading="card1"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
      >
        <div class="title">
          <p>{{ cardList[1].title }}</p>
          <p style="padding-left: 5px">{{ cardList[1].dateStr }}</p>
        </div>
        <p class="num">
          {{
            cardList[1].num
              ? String(cardList[1].num).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "--"
          }}
          <span style="font-size: 14px; color: #262626">万</span>
        </p>
        <p class="compare" v-show="cardList[1].rise">
          {{ cardList[1].txt }}
          <span
            :class="cardList[1].rise < 0 ? 'compare-con drop' : 'compare-con'"
          >
            <img
              v-if="cardList[1].rise < 0"
              class="question"
              src="@/assets/drop2.png"
            />
            <img v-else class="question" src="@/assets/rise2.png" />
            {{ cardList[1].rise }}%
          </span>
        </p>
        <p class="compare" v-show="!cardList[1].rise">暂无同比数据</p>
        <div class="item_icon">
          <svg-icon class="item_icon_svg" icon-class="tongji1" />
        </div>
      </div>

      <div
        class="item"
        :style="{ backgroundColor: cardList[2].backColor }"
        v-loading="card2"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
      >
        <div class="title">
          <p>{{ cardList[2].title }}</p>
          <p style="padding-left: 5px">{{ cardList[2].dateStr }}</p>
        </div>
        <p class="num">
          {{
            cardList[2].num
              ? String(cardList[2].num).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "--"
          }}
          <span style="font-size: 14px; color: #262626">万</span>
        </p>
        <p class="compare" v-show="cardList[2].rise">
          {{ cardList[2].txt }}
          <span
            :class="cardList[2].rise < 0 ? 'compare-con drop' : 'compare-con'"
          >
            <img
              v-if="cardList[2].rise < 0"
              class="question"
              src="@/assets/drop2.png"
            />
            <img v-else class="question" src="@/assets/rise2.png" />
            {{ cardList[2].rise }}%
          </span>
        </p>
        <p class="compare" v-show="!cardList[2].rise">暂无同比数据</p>
        <div class="item_icon">
          <svg-icon class="item_icon_svg" icon-class="tongji2" />
        </div>
      </div>

      <div
        class="item"
        :style="{ backgroundColor: cardList[3].backColor }"
        v-loading="card3"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
      >
        <div class="title">
          <p>{{ cardList[3].title }}</p>
          <p style="padding-left: 5px">{{ cardList[3].dateStr }}</p>
        </div>
        <p class="num">
          {{
            cardList[3].num
              ? String(cardList[3].num).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "--"
          }}
          <span style="font-size: 14px; color: #262626">万</span>
        </p>
        <p class="compare" v-show="cardList[3].rise">
          {{ cardList[3].txt }}
          <span
            :class="cardList[3].rise < 0 ? 'compare-con drop' : 'compare-con'"
          >
            <img
              v-if="cardList[3].rise < 0"
              class="question"
              src="@/assets/drop2.png"
            />
            <img v-else class="question" src="@/assets/rise2.png" />
            {{ cardList[3].rise }}%
          </span>
        </p>
        <p class="compare" v-show="!cardList[3].rise">暂无同比数据</p>
        <div class="item_icon">
          <svg-icon class="item_icon_svg" icon-class="tongji3" />
        </div>
      </div>
    </div>
    <div class="ratio_box">
      <div class="radio">
        <div
          class="left"
          v-loading="barChart1"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          element-loading-text="Loading"
          element-loading-spinner="el-icon-loading"
        >
          <BarChart
            v-if="barData.targetAmount && barData.saleAmount"
            :barData="barData"
            style="width: 50%; text-align: center"
          />
          <div
            v-else
            style="height: 350px; line-height: 350px; text-align: center"
          >
            暂无数据
          </div>

          <BarChart
            v-if="barData2.targetAmount && barData2.saleAmount"
            :barData="barData2"
            style="width: 50%; text-align: center"
          />
          <div
            v-else
            style="height: 350px; line-height: 350px; text-align: center"
          >
            暂无数据
          </div>
        </div>
        <div
          class="right"
          v-loading="barChart2"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          element-loading-text="Loading"
          element-loading-spinner="el-icon-loading"
        >
          <BarChart
            v-if="barData3.targetAmount && barData3.saleAmount"
            :barData="barData3"
            style="width: 100%; text-align: center"
          />
          <div
            v-else
            style="height: 350px; line-height: 350px; text-align: center"
          >
            暂无数据
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "./barChart";

export default {
  components: {
    BarChart,
  },
  props: {
    search: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      card0: false,
      card1: false,
      card2: false,
      card3: false,
      barChart1: false,
      barChart2: false,
      cardList: [
        {
          title: "日销售额",
          num: 0,
          backColor: "#E1DDEC", // 线条颜色
          rise: 0,
          txt: "较前一日",
        },
        {
          title: "周销售额",
          num: 0,
          backColor: "#CFD2E7", // 线条颜色
          rise: 0,
          txt: "较上周",
        },
        {
          title: "月销售额",
          num: 0,
          backColor: "#CBD8DF", // 线条颜色
          rise: 0,
          txt: "较上月",
        },
        {
          title: "年销售额",
          num: 0,
          backColor: "#D7DFCC", // 线条颜色
          rise: 0,
          txt: "较上年",
        },
      ],
      barData: {
        id: "bar1",
        txt: "月度目标额",
        title: "月度目标达成进度",
        desc: `规则说明：<br />
              1、月度目标达成进度<br />
              1.1 达成进度=选中日期所属月份总销售额 / 目标值*100%<br />
              1.2 每月1日起重新计算当月达成进度<br />
              1.3 按照所选月份及所选组织架构下的地区目标相加计算，如选中部门是多个地区，目标是多个地区相加。`,
        targetAmount: undefined,
        saleAmount: undefined,
      },
      barData2: {
        id: "bar2",
        txt: "年度目标额",
        title: "年度目标达成进度",
        desc: `规则说明：<br />
              1、年度目标达成进度 <br />
              1.1 达成进度=选中日期年份销售额/目标值*100%<br />
              1.2 每年1月1日起重新计算当年进度<br />
              1.3 组织架构口径：<br />
              当前选择部门是地区时：按照地区设置的月目标相加等于地区年度总目标<br />
              当前选择部门是大区时：按照大区下所辖地区月目标相加等于大区年总目标<br />
              如组织架构勾选多部门，年目标等于多部门目标之和`,
        targetAmount: undefined,
        saleAmount: undefined,
      },
      barData3: {
        id: "bar3",
        txt: "序时目标额",
        title: "序时目标达成进度",
        desc: `规则说明<br />
              1、序时目标达成进度<br />
              1.1 序时达成率=（1月1日至选中月份销售额总和/1月1日截止选中月目标总和）*100% `,
        targetAmount: undefined,
        saleAmount: undefined,
      },
    };
  },
  watch: {
    search(newV) {
      console.log(newV, 123);
      this.getDaySale();
      this.getWeekSale();
      this.getMonthSale();
      this.getYearSale();
      this.getChart();
    },
  },
  methods: {
    getDaySale() {
      this.card0 = true;
      this.axios
        .post("HOMEdAY", this.search, true, { showLoading: false })
        .then((res) => {
          this.cardList[0].num = res.data?.saleAmount;
          this.cardList[0].rise = res.data?.saleRatio;
          this.cardList[0].dateStr = res.data?.dateStr;
          this.card0 = false;
        })
        .finally(() => {
          this.card0 = false;
        });
    },
    getWeekSale() {
      this.card1 = true;
      this.axios
        .post("HOMEWEEK", this.search, true, { showLoading: false })
        .then((res) => {
          this.cardList[1].num = res.data?.saleAmount;
          this.cardList[1].rise = res.data?.saleRatio;
          this.cardList[1].dateStr = res.data?.dateStr;
          this.card1 = false;
        })
        .finally(() => {
          this.card1 = false;
        });
    },
    getMonthSale() {
      this.card2 = true;
      this.axios
        .post("HOMEMONTH", this.search, true, { showLoading: false })
        .then((res) => {
          this.cardList[2].num = res.data?.saleAmount;
          this.cardList[2].rise = res.data?.saleRatio;
          this.cardList[2].dateStr = res.data?.dateStr;
          this.card2 = false;
        })
        .finally(() => {
          this.card2 = false;
        });
    },
    getYearSale() {
      this.card3 = true;
      this.axios
        .post("HOMEYEAR", this.search, true, { showLoading: false })
        .then((res) => {
          this.cardList[3].num = res.data?.saleAmount;
          this.cardList[3].rise = res.data?.saleRatio;
          this.cardList[3].dateStr = res.data?.dateStr;
          this.card3 = false;
        })
        .finally(() => {
          this.card3 = false;
        });
    },

    getChart() {
      this.barChart1 = true;
      this.axios
        .post("HOMETARGETYEAR", this.search, true, { showLoading: false })
        .then((res) => {
          this.barData2.targetAmount = res.data?.targetAmount?.replace(
            /,/g,
            ""
          );
          this.barData2.saleRatio = res.data?.saleRatio
            ? (Number(res.data?.saleRatio) * 100).toFixed(2)
            : 0.0;
          this.barData2.saleAmount = res.data?.saleAmount?.replace(/,/g, "");
          console.log("this.barData2", this.barData2);
        })
        .finally(() => {
          this.barChart1 = false;
        });

      this.axios
        .post("HOMETARGETMONTH", this.search, true, { showLoading: false })
        .then((res) => {
          this.barData.targetAmount = res.data?.targetAmount?.replace(/,/g, "");
          this.barData.saleAmount = res.data?.saleAmount?.replace(/,/g, "");
          this.barData.saleRatio = res.data?.saleRatio
            ? (Number(res.data?.saleRatio) * 100).toFixed(2)
            : 0.0;
        })
        .finally(() => {
          this.barChart1 = false;
        });
      this.barChart2 = true;
      this.axios
        .post("HOMETARGETTIME", this.search, true, { showLoading: false })
        .then((res) => {
          this.barData3.targetAmount = res.data?.targetAmount?.replace(
            /,/g,
            ""
          );
          this.barData3.saleRatio = res.data?.saleRatio
            ? (Number(res.data?.saleRatio) * 100).toFixed(2)
            : 0.0;
          this.barData3.saleAmount = res.data?.saleAmount?.replace(/,/g, "");
        })
        .finally(() => {
          this.barChart2 = false;
        });
    },
    format(percentage) {
      return "月度目标额" + "\n\n" + percentage.toFixed(2) + "万";
    },
    format2(percentage) {
      return "年度目标额" + "\n\n" + percentage.toFixed(2) + "万";
    },
    format3(percentage) {
      return "序时目标额" + "\n\n" + percentage.toFixed(2) + "万";
    },
  },
};
</script>

<style scoped lang="scss">
.count_box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  margin-top: 24px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .count_box_title {
    width: 100%;
    padding-bottom: 10px;
    color: #262626;
    font-weight: bold;
    font-size: 16px;
  }
  .count_box_title_icon {
    font-size: 20px;
    margin-left: 5px;
  }

  .item {
    width: 23%;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
    .title {
      position: relative;
      z-index: 99;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      // line-height: 22px;
      display: flex;
      .tipBlock {
        width: 16px;
        height: 16px;
        margin-left: 3px;
      }
    }
    .num {
      position: relative;
      z-index: 99;
      font-size: 32px;
      font-family: MiSans-Demibold;
      font-weight: 600;
      color: #262626;
      line-height: 32px;
      letter-spacing: 1px;
      margin: 20px 0 10px 0;
    }
    .compare {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #262626;
      // line-height: 22px;
    }
    .compare-con {
      width: 47px;
      height: 18px;
      background: #ffffff;
      border-radius: 4px;
      opacity: 0.9;
      padding: 4px;
      box-sizing: border-box;
      color: #ff5322;
      img {
        display: inline;
        width: 10px;
        height: 10px;
        margin-top: 4px;
      }
    }
    .drop {
      color: #00a4ff;
    }

    .item_icon {
      position: absolute;
      right: 24px;
      bottom: 24px;
    }

    .item_icon_svg {
      width: 40px;
      height: 40px;
    }
  }
}
.ratio_box {
  margin-top: 24px;
  .radio {
    display: flex;
    ::v-deep {
      .el-progress__text {
        white-space: pre;
        font-weight: 700;
        font-size: 18px !important;
      }
    }
    .left {
      width: 65%;
      padding: 24px;
      margin-right: 25px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
      display: flex;
      justify-content: space-around;
    }
    .right {
      width: 35%;
      padding: 24px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
      display: flex;
      justify-content: center;
    }
  }
}
</style>
