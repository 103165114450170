<template>
  <div>
    <div class="box">
      <div class="title">
        产品组销售进度
        <el-popover placement="right" trigger="hover" width="500">
          <div>
            规则说明：<br />
            1、按商品所关联的产品组进行销售业绩统计<br />
            <br />
            2、日周月年销售额计算口径：<br />
            当组织架构为大区及以上部门时，业绩按照大区口径归集；<br />
            当组织架构为地区时，业绩按照地区关联客户进行业绩归集；<br />
            <br />
            3、进度达成计算方式：<br />
            目标设置维度是地区+产品组，进度按照所选择部门下的产品组目标额度相加，与当前选中日期的月、年是销售额目标计算，具体计算方式可查看进度目标说明模块。
          </div>
          <i slot="reference" class="el-icon-question count_box_title_icon"></i>
        </el-popover>
      </div>
      <el-table
        :data="tableData1"
        class="box_table"
        v-loading="table1Loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
        :header-cell-style="{
          background: '#f5f6f8',
          color: '#5C6474',
        }"
      >
        <el-table-column
          prop="productGroupName"
          label="产品组"
          align="left"
          width="150"
        >
          <template slot="header" slot-scope="scope">
            <div style="text-indent: 20px">产品组</div>
          </template>
          <template slot-scope="scope">
            <div style="text-indent: 20px">
              {{ scope.row.productGroupName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="daySaleAmount"
          label="日销售额"
          align="right"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="weekSaleAmount" label="周销售额" align="right">
        </el-table-column>
        <el-table-column prop="monthSaleAmount" label="月销售额" align="right">
        </el-table-column>
        <el-table-column prop="yearSaleAmount" label="年销售额" align="right">
        </el-table-column>
        <el-table-column prop="monthRatio" label="月度达成进度" align="right">
        </el-table-column>
        <el-table-column prop="yearRatio" label="年度达成进度" align="right">
        </el-table-column>
        <el-table-column
          prop="timeRatio"
          label="序时目标达成进度"
          align="right"
          width="250"
        >
          <template slot="header" slot-scope="scope">
            <div style="padding-right: 20px">序时目标达成进度</div>
          </template>
          <template slot-scope="scope">
            <div style="padding-right: 20px">
              {{ scope.row.timeRatio }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="box">
      <div class="title">
        部门销售进度
        <el-popover placement="right" trigger="hover" width="500">
          <div>
            规则说明：<br />
            1、部门销售进度：<br />
            按照当前组织架构选中的部门，展示下级部门销售业绩汇总数据。<br />
            <br />
            2、日、周、月、年按照辖区部门汇总规则统计，如筛选项中的日期变更，统计数据需要按照最新日期规则汇总<br />
            <br />
            3、月度达成、年度达成按照辖区已设置目标及实际销售额计算，如遇未设置情况，则展示为“-”<br />
            <br />
            4、统计口径说明：<br />
            大区口径：通常指查看的架构是大区及以上部门时业绩汇总计算方式，规则是大区按照下单医生所在医院省市进行业绩归集。<br />
            地区口径：通常指查看的部门是地区时业绩汇总的计算方式，规则是按照下单医生所归属地区进行业绩归集。
          </div>
          <i slot="reference" class="el-icon-question count_box_title_icon"></i>
        </el-popover>
      </div>
      <el-table
        :data="tableData2"
        v-loading="table2Loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
        class="box_table"
        :header-cell-style="{
          background: '#f5f6f8',
          color: '#5C6474',
        }"
      >
        <el-table-column prop="curDeptName" label="当前部门" align="left">
          <template slot="header" slot-scope="scope">
            <div style="text-indent: 20px">当前部门</div>
          </template>
          <template slot-scope="scope">
            <div style="text-indent: 20px">
              {{ scope.row.curDeptName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="subDeptName"
          label="下级部门"
          align="left"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="daySaleAmount"
          label="日销售额"
          align="right"
          width="130"
        >
        </el-table-column>
        <el-table-column prop="weekSaleAmount" label="周销售额" align="right">
        </el-table-column>
        <el-table-column prop="monthSaleAmount" label="月销售额" align="right">
        </el-table-column>
        <el-table-column prop="yearSaleAmount" label="年销售额" align="right">
        </el-table-column>
        <el-table-column prop="monthRatio" label="月度达成进度" align="right">
        </el-table-column>
        <el-table-column prop="yearRatio" label="年度达成进度" align="right">
        </el-table-column>
        <el-table-column
          prop="timeRatio"
          label="序时达成进度"
          align="right"
          width="200"
        >
          <template slot="header" slot-scope="scope">
            <div style="padding-right: 20px">序时达成进度</div>
          </template>
          <template slot-scope="scope">
            <div style="padding-right: 20px">
              {{ scope.row.timeRatio }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageConfig2.pageNum"
        :page-size="pageConfig2.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, prev, pager, next"
        :total="total2"
        style="float: right; margin-top: 15px"
        @size-change="handleSizeChange2"
        @current-change="handleCurrentChange2"
      />
    </div>
    <div class="box">
      <div class="title">
        销售业务（按是否准入）
        <el-popover placement="right" trigger="hover" width="500">
          <div>
            规则说明：<br />
            1、销售业务统计（按是否准入）<br />
            1.1 日、周、月、年按照时间规则计算<br />
            1.2 准入月结：送检类型为-普通送检、医院自检、支付方式为月结的订单<br />
            1.3 其他外送：非准入月结的订单均为其他外送<br />
            2、统计口径说明：<br />
            大区口径：通常指查看的架构是大区及以上部门时业绩汇总计算方式，规则是大区按照下单医生所在医院省市进行业绩归集。<br />
            地区口径：通常指查看的部门是地区时业绩汇总的计算方式，规则是按照下单医生所归属地区进行业绩归集。
          </div>
          <i slot="reference" class="el-icon-question count_box_title_icon"></i>
        </el-popover>
      </div>
      <el-table
        :data="tableData3"
        style="width: 100%"
        class="box_table"
        v-loading="table3Loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
        :header-cell-style="{
          background: '#f5f6f8',
          color: '#5C6474',
        }"
      >
        <el-table-column prop="curDeptName" label="当前部门" align="center">
        </el-table-column>
        <el-table-column prop="subDeptName" label="下级部门" align="center">
        </el-table-column>
        <el-table-column label="日销售额" align="center">
          <el-table-column
            prop="daySale.accessAmount"
            label="准入月结"
            align="center"
          />
          <el-table-column
            prop="daySale.otherAmount"
            label="其他外送"
            align="center"
          />
          <el-table-column
            prop="daySale.totalAmount"
            label="合计"
            align="center"
          />
        </el-table-column>
        <el-table-column label="周销售额" align="center">
          <el-table-column
            prop="weekSale.accessAmount"
            label="准入月结"
            align="center"
          />
          <el-table-column
            prop="weekSale.otherAmount"
            label="其他外送"
            align="center"
          />
          <el-table-column
            prop="weekSale.totalAmount"
            label="合计"
            align="center"
          />
        </el-table-column>
        <el-table-column label="月销售额" align="center">
          <el-table-column
            prop="monthSale.accessAmount"
            label="准入月结"
            align="center"
          />
          <el-table-column
            prop="monthSale.otherAmount"
            label="其他外送"
            align="center"
          />
          <el-table-column
            prop="monthSale.totalAmount"
            label="合计"
            align="center"
          />
        </el-table-column>
        <el-table-column label="年销售额" align="center">
          <el-table-column
            prop="yearSale.accessAmount"
            label="准入月结"
            align="center"
          />
          <el-table-column
            prop="yearSale.otherAmount"
            label="其他外送"
            align="center"
          />
          <el-table-column
            prop="yearSale.totalAmount"
            label="合计"
            align="center"
          />
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageConfig3.pageNum"
        :page-size="pageConfig3.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, prev, pager, next"
        :total="total3"
        style="float: right; margin-top: 15px"
        @size-change="handleSizeChange3"
        @current-change="handleCurrentChange3"
      />
    </div>
    <div class="box">
      <div class="title">
        销售业务（按队伍属性）
        <el-popover placement="right" trigger="hover" width="500">
          <div>
            规则说明：<br />
            1、统计口径说明：<br />
            大区口径：通常指查看的架构是大区及以上部门时业绩汇总计算方式，规则是大区按照下单医生所在医院省市进行业绩归集。<br />
            地区口径：通常指查看的部门是地区时业绩汇总的计算方式，规则是按照下单医生所归属地区进行业绩归集。
          </div>
          <i slot="reference" class="el-icon-question count_box_title_icon"></i>
        </el-popover>
      </div>
      <el-table
        :data="tableData4"
        v-loading="table4Loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
        class="box_table"
        :header-cell-style="{
          background: '#f5f6f8',
          color: '#5C6474',
        }"
      >
        <el-table-column prop="curDeptName" label="当前部门" align="center">
        </el-table-column>
        <el-table-column prop="subDeptName" label="下级部门" align="center">
        </el-table-column>
        <el-table-column label="日销售额" align="center">
          <el-table-column
            prop="daySale.simceredxAmount"
            label="专员直单"
            align="center"
          />
          <el-table-column
            prop="daySale.simcereAmount"
            label="经理直单"
            align="center"
          />
          <el-table-column
            prop="daySale.invitedPartnerAmount"
            label="自然人"
            align="center"
          />
          <el-table-column
            prop="daySale.totalAmount"
            label="合计"
            align="center"
          />
        </el-table-column>
        <el-table-column label="周销售额" align="center">
          <el-table-column
            prop="weekSale.simceredxAmount"
            label="专员直单"
            align="center"
          />
          <el-table-column
            prop="weekSale.simcereAmount"
            label="经理直单"
            align="center"
          />
          <el-table-column
            prop="weekSale.invitedPartnerAmount"
            label="自然人"
            align="center"
          />
          <el-table-column
            prop="weekSale.totalAmount"
            label="合计"
            align="center"
          />
        </el-table-column>
        <el-table-column label="月销售额" align="center">
          <el-table-column
            prop="monthSale.simceredxAmount"
            label="专员直单"
            align="center"
          />
          <el-table-column
            prop="monthSale.simcereAmount"
            label="经理直单"
            align="center"
          />
          <el-table-column
            prop="monthSale.invitedPartnerAmount"
            label="自然人"
            align="center"
          />
          <el-table-column
            prop="monthSale.totalAmount"
            label="合计"
            align="center"
          />
        </el-table-column>
        <el-table-column label="年销售额" align="center">
          <el-table-column
            prop="yearSale.simceredxAmount"
            label="专员直单"
            align="center"
          />
          <el-table-column
            prop="yearSale.simcereAmount"
            label="经理直单"
            align="center"
          />
          <el-table-column
            prop="yearSale.invitedPartnerAmount"
            label="自然人"
            align="center"
          />
          <el-table-column
            prop="yearSale.totalAmount"
            label="合计"
            align="center"
          />
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageConfig4.pageNum"
        :page-size="pageConfig4.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, prev, pager, next"
        :total="total4"
        style="float: right; margin-top: 15px"
        @size-change="handleSizeChange4"
        @current-change="handleCurrentChange4"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    search: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      pageConfig2: {
        pageNum: 1,
        pageSize: 10,
        sort: "asc",
      },
      pageConfig3: {
        pageNum: 1,
        pageSize: 10,
        sort: "asc",
      },
      pageConfig4: {
        pageNum: 1,
        pageSize: 10,
        sort: "asc",
      },
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      total2: 0,
      total3: 0,
      total4: 0,
      table1Loading: false,
      table2Loading: false,
      table3Loading: false,
      table4Loading: false,
    };
  },
  watch: {
    search(newV) {
      this.getProductGroupProgress();
      this.getDeptProgress();
      this.getSaleAccess();
      this.getSaleOaType();
    },
  },
  methods: {
    getProductGroupProgress() {
      this.table1Loading = true;
      this.axios
        .post("HOMEproductGroupSaleProgress", { ...this.search }, true, {
          showLoading: false,
        })
        .then((res) => {
          this.tableData1 = res.data;
        })
        .finally(() => {
          this.table1Loading = false;
        });
    },

    getDeptProgress() {
      this.table2Loading = true;

      this.axios
        .post(
          "DEPTSALEPROGRESS",
          { ...this.search, ...this.pageConfig2 },
          true,
          { showLoading: false }
        )
        .then((res) => {
          this.tableData2 = res.data?.list;
          this.total2 = res.data?.total;
        })
        .finally(() => {
          console.log("1212");
          this.table2Loading = false;
        });
    },

    getSaleAccess() {
      this.table3Loading = true;

      this.axios
        .post("HOMEsaleAccess", { ...this.search, ...this.pageConfig3 }, true, {
          showLoading: false,
        })
        .then((res) => {
          this.tableData3 = res.data?.list;
          this.total3 = res.data?.total;
        })
        .finally(() => {
          this.table3Loading = false;
        });
    },

    getSaleOaType() {
      this.table4Loading = true;

      this.axios
        .post("HOMEsaleOaType", { ...this.search, ...this.pageConfig4 }, true, {
          showLoading: false,
        })
        .then((res) => {
          this.tableData4 = res.data?.list;
          this.total4 = res.data?.total;
        })
        .finally(() => {
          this.table4Loading = false;
        });
    },
    handleCurrentChange2(v) {
      this.pageConfig2.pageNum = v;
      this.getDeptProgress();
    },
    handleSizeChange2(v) {
      this.pageConfig2.pageSize = v;
      this.getDeptProgress();
    },
    handleCurrentChange3(v) {
      this.pageConfig3.pageNum = v;
      this.getSaleAccess();
    },
    handleSizeChange3(v) {
      this.pageConfig3.pageSize = v;
      this.getSaleAccess();
    },
    handleCurrentChange4(v) {
      this.pageConfig4.pageNum = v;
      this.getSaleOaType();
    },
    handleSizeChange4(v) {
      this.pageConfig4.pageSize = v;
      this.getSaleOaType();
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  margin-top: 24px;
  padding: 24px 24px;

  overflow: hidden;
  .title {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 10px;
  }

  .box_table {
    border: none;
  }

  .count_box_title_icon {
    font-size: 20px;
    vertical-align: middle;
  }

  .el-table::before {
    height: 0 !important;
  }
}
</style>
