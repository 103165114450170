<template>
  <div class="content">
    <!-- 交易概览 -->
    <overview @on-search="onSearch" />
    <CountTotal :search="search" />
    <TablesView :search="search" />
    <AreaView :search="search" />
    <div style="height: 10px"></div>
  </div>
</template>

<script>
import Overview from "./components/overview";
import CountTotal from "./components/countToal";
import TablesView from "./components/tablesView";
import AreaView from "./components/areaview";

export default {
  name: "Home",
  components: {
    Overview,
    CountTotal,
    TablesView,
    AreaView,
  },
  data() {
    return {
      search: {},
    };
  },
  mounted() {},
  methods: {
    onSearch(params) {
      console.log("params", params);
      this.search = { ...params };
    },
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  height: 100vh;
}

.card-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pic-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-pic {
  width: 600px;
  height: 345px;
}
.title {
  /* font-weight: bold; */
  /* font-size: 25px; */
  color: #262626;
  letter-spacing: 3px;
  margin-top: 50px;
}
</style>
