<template>
  <div>
    <div class="bar-title">
      {{ barData.title }}
      <el-popover placement="right" trigger="hover" width="500">
        <div v-html="barData.desc"></div>
        <i slot="reference" class="el-icon-question count_box_title_icon"></i>
      </el-popover>
    </div>
    <div :id="barData.id" :style="{ height: '380px' }"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    barData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      color: ["#5EB4FF", "#F0F0F0", "#4c4a4a"],
      showChart: false,
    };
  },
  watch: {
    barData: {
      handler: function (newV) {
        console.log("New", newV);
        if (newV.id == "bar2") {
          this.color = ["#776198", "#F0F0F0", "#4c4a4a"];
        } else if (newV.id == "bar3") {
          this.color = ["#377F7F", "#F0F0F0", "#4c4a4a"];
        } else {
          this.color = ["#5EB4FF", "#F0F0F0", "#4c4a4a"];
        }
        this.$nextTick(() => {
          console.log("this.barData", this.barData);
          if (this.barData.saleAmount && this.barData.targetAmount) {
            this.drawLine();
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // this.drawLine();
  },
  methods: {
    drawLine() {
      var chartDom = document.getElementById(this.barData.id);
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: this.color,
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            hoverAnimation: false,
            type: "pie",
            radius: ["50%", "80%"],
            label: {
              normal: {
                show: true,
                position: "center",
                color: "#4c4a4a",
                formatter:
                  `{active|${this.barData.txt}}` +
                  "\n\r" +
                  "{total|" +
                  this.barData.targetAmount +
                  "万}" +
                  "\n\r" +
                  `{percent|已达成${this.barData.saleRatio}%}`,
                rich: {
                  total: {
                    fontSize: 35,
                    fontFamily: "微软雅黑",
                    color: "#454c5c",
                  },
                  percent: {
                    fontSize: 15,
                    fontFamily: "微软雅黑",
                    color: this.color[0],
                    lineHeight: 20,
                  },
                  active: {
                    fontFamily: "微软雅黑",
                    fontSize: 16,
                    color: "#6c7a89",
                    lineHeight: 30,
                  },
                },
              },
              emphasis: {
                //中间文字显示
                show: true,
              },
            },
            data: [
              {
                value: this.barData.saleAmount,
                name: "已完成目标额",
              },
              {
                value:
                  this.barData.targetAmount && this.barData.saleAmount
                    ? (
                        Number(this.barData.targetAmount) -
                        Number(this.barData.saleAmount)
                      ).toFixed(2)
                    : 0,
                name: "未完成目标额",
              },
            ],
          },
        ],
      };
      myChart.on("mouseover", (e) => {
        let op = myChart.getOption();
        if (e.name === "未完成目标额") {
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: e.dataIndex,
            color: e.color,
          });
          myChart.setOption(op, true);
        }
      });
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
.bar-title {
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  display: flex;
  align-items: center;
}

.count_box_title_icon {
  font-size: 20px;
  margin-left: 5px;
}
</style>
